import * as ReactQuery from '@tanstack/react-query';
import React from 'react';
import * as JsxDevRuntime from 'react/jsx-dev-runtime';
import * as JsxRuntime from 'react/jsx-runtime';

let cxModulesApiEndpoint = '';

try {
  cxModulesApiEndpoint = process.env.CX_RESOURCES_LOCATION;
} catch {}

const options = {
  insertCss: false,
  dependenciesMap: {
    '@tanstack/react-query': ReactQuery,
    react: React,
    'react/jsx-dev-runtime': JsxDevRuntime,
    'react/jsx-runtime': JsxRuntime
  }
};

export const cxModuleLoader = async (
  loadClient,
  shadowRoot,
  optionsOverride = false
) => {
  try {
    const { Component, cssBundle } = await loadClient(
      cxModulesApiEndpoint,
      undefined,
      optionsOverride ?? options
    );

    const cssLinkTag = document.createElement('link');
    cssLinkTag.rel = 'stylesheet';
    cssLinkTag.href = cssBundle;

    shadowRoot.prepend(cssLinkTag);
    return { Component };
  } catch (error) {
    if (error) {
      error.errorOrigin = 'cxModuleLoader';
      throw error;
    } else {
      throw 'cxModuleLoader Error';
    }
  }
};
